import { always, cond, equals } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useMount } from 'react-use';

import Tabs, { Tab } from '~/components/Tabs';
import urls, { reverse } from '~/views/urls';

import {
  ACCOUNT_SETTINGS_TAB as TAB,
  ACCOUNT_SETTINGS_TAB_LABEL as TAB_LABEL,
} from './AccountSettings.constants';
import { Container, TabContent } from './AccountSettings.styled';
import General from './components/General';
import Notifications from './components/Notifications';
import PasswordChange from './components/PasswordChange';

const AccountSettings = () => {
  const { t } = useTranslation();
  const { settingsTabSlug } = useParams();
  const history = useHistory();
  const isProperTab = Object.values(TAB).includes(settingsTabSlug);
  const initialTab = isProperTab ? settingsTabSlug : TAB.GENERAL;
  const [selectedTab, setSelectedTab] = useState(initialTab);

  useEffect(() => {
    setSelectedTab(settingsTabSlug);
  }, [settingsTabSlug]);

  const handleTabChange = useCallback(
    (event, value) => {
      history.push(reverse(urls.accountSettings, { settingsTabSlug: value }));
      setSelectedTab(value);
    },
    [history],
  );

  useMount(() => {
    if (!isProperTab) {
      handleTabChange(null, initialTab);
    }
  });

  const renderTabContent = cond([
    [equals(TAB.GENERAL), always(<General />)],
    [equals(TAB.PASSWORD), always(<PasswordChange />)],
    [equals(TAB.NOTIFICATIONS), always(<Notifications />)],
  ]);

  const HeaderContent = useMemo(
    () => (
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab label={TAB_LABEL[TAB.GENERAL]} value={TAB.GENERAL} />
        <Tab label={TAB_LABEL[TAB.PASSWORD]} value={TAB.PASSWORD} />
        <Tab label={TAB_LABEL[TAB.NOTIFICATIONS]} value={TAB.NOTIFICATIONS} />
      </Tabs>
    ),
    [handleTabChange, selectedTab],
  );

  return (
    <Container
      data-testid="AccountSettings"
      title={t('accountSettings.title', 'Account settings')}
      centeredContent
      withBackButton
      HeaderContent={HeaderContent}
    >
      <Helmet
        title={t('accountSettings.pageTitle', 'Account settings / {{ tab }}', {
          tab: TAB_LABEL[selectedTab],
        })}
      />
      <TabContent>{renderTabContent(settingsTabSlug)}</TabContent>
    </Container>
  );
};

export default AccountSettings;
