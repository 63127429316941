import Input from '@bugbug/core/components/Input';
import { useFormik } from 'formik';
import { pick } from 'ramda';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import useActionState from '~/hooks/useActionState';
import { UserActions } from '~/modules/user/user.redux';
import { selectUserData } from '~/modules/user/user.selectors';
import * as validators from '~/utils/validators';

import { Row, Column, Label, SubmitButton } from '../../AccountSettings.styled';

const GeneralSchema = Yup.object().shape({
  firstName: validators.nameValidator,
  lastName: validators.nameValidator,
});

const General = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profileData = useSelector(selectUserData);

  const handleOnSubmit = (values) => {
    dispatch(UserActions.updateDataRequest(values));
  };

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    setErrors,
    setSubmitting,
    isSubmitting,
  } = useFormik({
    initialValues: pick(['firstName', 'lastName'], profileData),
    validationSchema: GeneralSchema,
    onSubmit: handleOnSubmit,
  });

  const handleFailure = useCallback(
    (stateErrors) => {
      if (stateErrors) {
        setErrors(stateErrors);
      }
      setSubmitting(false);
    },
    [setSubmitting, setErrors],
  );

  const handleSuccess = useCallback(() => {
    setSubmitting(false);
  }, [setSubmitting]);

  const { isLoading, isSuccess } = useActionState(UserActions.updateDataRequest, {
    onFailure: handleFailure,
    onSuccess: handleSuccess,
  });

  return (
    <form noValidate data-testid="General" onSubmit={handleSubmit}>
      <Row>
        <Column>
          <Label>{t('accountSettings.general.form.firstName.label', 'First name')}</Label>
        </Column>
        <Column>
          <Input
            name="firstName"
            placeholder={t('accountSettings.general.form.firstName.placeholder', 'First name')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            error={touched.firstName && errors.firstName}
            fullWidth
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>{t('accountSettings.general.form.lastName.label', 'Last name')}</Label>
        </Column>
        <Column>
          <Input
            name="lastName"
            placeholder={t('accountSettings.general.form.lastName.placeholder', 'Last name')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            error={touched.lastName && errors.lastName}
            fullWidth
          />
        </Column>
      </Row>

      <SubmitButton succeeded={isSuccess} disabled={isSubmitting} pending={isLoading}>
        {t('default.button.save')}
      </SubmitButton>
    </form>
  );
};

export default General;
