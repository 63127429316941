import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import useActionState from '~/hooks/useActionState';
import { UserActions } from '~/modules/user/user.redux';
import { selectUserSettings } from '~/modules/user/user.selectors';

import { Row, Column, Label, SubmitButton } from '../../AccountSettings.styled';

import { Checkbox } from './Notifications.styled';

const NotificationsSchema = Yup.object().shape({
  isAllowedEmailMarketing: Yup.bool().optional(),
});

const Notifications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userSettings = useSelector(selectUserSettings);

  const handleOnSubmit = (values) => {
    dispatch(UserActions.updateSettingsRequest(NotificationsSchema.cast(values)));
  };

  const { handleChange, handleSubmit, values, setErrors, setSubmitting, isSubmitting } = useFormik({
    initialValues: {
      isAllowedEmailMarketing: userSettings.isAllowedEmailMarketing,
    },
    validationSchema: NotificationsSchema,
    onSubmit: handleOnSubmit,
  });

  const handleFailure = useCallback(
    (stateErrors) => {
      if (stateErrors) {
        setErrors(stateErrors);
      }
      setSubmitting(false);
    },
    [setSubmitting, setErrors],
  );

  const handleSuccess = useCallback(() => {
    setSubmitting(false);
  }, [setSubmitting]);

  const { isLoading, isSuccess } = useActionState(UserActions.updateSettingsRequest, {
    onFailure: handleFailure,
    onSuccess: handleSuccess,
  });

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Row>
        <Column>
          <Label>
            {t(
              'accountSettings.notifcations.form.isAllowedEmailMarketing.label',
              'E-mail marketing consent',
            )}
          </Label>
        </Column>
        <Column>
          <Checkbox
            name="isAllowedEmailMarketing"
            checked={values.isAllowedEmailMarketing}
            onChange={handleChange}
          >
            {t(
              'accountSettings.notifcations.form.isAllowedEmailMarketing.value',
              'I want to receive information about bugbug.io special offers on my e-mail',
            )}
          </Checkbox>
        </Column>
      </Row>

      <SubmitButton succeeded={isSuccess} disabled={isSubmitting} pending={isLoading}>
        {t('default.button.save')}
      </SubmitButton>
    </form>
  );
};

export default Notifications;
