import Input from '@bugbug/core/components/Input';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import useActionState from '~/hooks/useActionState';
import { UserActions } from '~/modules/user/user.redux';
import * as validators from '~/utils/validators';

import { Row, Column, Label, SubmitButton } from '../../AccountSettings.styled';

const PasswordChangeSchema = Yup.object().shape({
  oldPassword: validators.oldPasswordValidator,
  newPassword1: validators.newPasswordValidator,
  newPassword2: validators.repeatNewPasswordValidator,
});

const PasswordChange = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOnSubmit = (values) => {
    dispatch(UserActions.passwordChangeRequest(values));
  };

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    setErrors,
    setSubmitting,
    isSubmitting,
  } = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword1: '',
      newPassword2: '',
    },
    validationSchema: PasswordChangeSchema,
    onSubmit: handleOnSubmit,
  });

  const handleFailure = useCallback(
    (stateErrors) => {
      if (stateErrors) {
        setErrors(stateErrors);
      }
      setSubmitting(false);
    },
    [setSubmitting, setErrors],
  );

  const handleSuccess = useCallback(() => {
    setSubmitting(false);
  }, [setSubmitting]);

  const { isLoading, isSuccess } = useActionState(UserActions.passwordChangeRequest, {
    onFailure: handleFailure,
    onSuccess: handleSuccess,
  });

  return (
    <form noValidate data-testid="PasswordChange" onSubmit={handleSubmit}>
      <Row>
        <Column>
          <Label>
            {t('accountSettings.passwordChange.form.oldPassword.label', 'Old password')}
          </Label>
        </Column>
        <Column>
          <Input
            type="password"
            name="oldPassword"
            placeholder={t(
              'accountSettings.passwordChange.form.oldPassword.placeholder',
              'Old password',
            )}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.oldPassword}
            error={touched.oldPassword && errors.oldPassword}
            fullWidth
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>
            {t('accountSettings.passwordChange.form.newPassword1.label', 'New password')}
          </Label>
        </Column>
        <Column>
          <Input
            type="password"
            name="newPassword1"
            placeholder={t(
              'accountSettings.passwordChange.form.newPassword1.placeholder',
              'Enter new password',
            )}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.newPassword1}
            error={touched.newPassword1 && errors.newPassword1}
            fullWidth
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>
            {t('accountSettings.passwordChange.form.newPassword2.label', 'Confirm password')}
          </Label>
        </Column>
        <Column>
          <Input
            type="password"
            name="newPassword2"
            placeholder={t(
              'accountSettings.passwordChange.form.newPassword2.placeholder',
              'Confirm new password',
            )}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.newPassword2}
            error={touched.newPassword2 && errors.newPassword2}
            fullWidth
          />
        </Column>
      </Row>

      <SubmitButton succeeded={isSuccess} disabled={isSubmitting} pending={isLoading}>
        {t('default.button.save')}
      </SubmitButton>
    </form>
  );
};

export default PasswordChange;
