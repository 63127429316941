import i18n from '~/translations';

export const ACCOUNT_SETTINGS_TAB = {
  GENERAL: 'general',
  PASSWORD: 'password-change',
  NOTIFICATIONS: 'notifications',
};

export const ACCOUNT_SETTINGS_TAB_LABEL = {
  [ACCOUNT_SETTINGS_TAB.GENERAL]: i18n.t('accountSettings.tabs.general.label', 'General'),
  [ACCOUNT_SETTINGS_TAB.PASSWORD]: i18n.t('accountSettings.tabs.users.label', 'Change password'),
  [ACCOUNT_SETTINGS_TAB.NOTIFICATIONS]: i18n.t(
    'accountSettings.tabs.notifications.label',
    'Notifications',
  ),
};
